import { IconButton, type IconButtonProps, SvgIcon, type SvgIconProps, type SvgIconTypeMap } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';

export type MuiIconButtonProps = Pick<IconButtonProps, 'disabled' | 'onClick' | 'sx' | 'style'> & {
  icon:
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >
    | (OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
        muiName: string;
      });
  size?: 'small' | 'medium';
  'aria-label'?: string;
} & Pick<SvgIconProps, 'color'>;

export const MuiIconButton = ({
  disabled,
  size,
  onClick,
  icon,
  sx,
  'aria-label': ariaLabel,
  color,
}: MuiIconButtonProps) => (
  <IconButton disabled={disabled} size={size} onClick={onClick} sx={sx} aria-label={ariaLabel}>
    <SvgIcon fontSize="inherit" component={icon} inheritViewBox color={color} />
  </IconButton>
);
